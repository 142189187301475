const appConfig = {
  apiPrefix: "https://api.edmrc.5techg.com",
  // apiPrefix: "http://localhost:8000",
  authenticatedEntryPath: "/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
  enableMock: true,
};

export default appConfig;
